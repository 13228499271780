/**-----------------------------------------------------------------------------------------
* Copyright © 2020 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import { __decorate, __metadata } from 'tslib';
import { EventEmitter, Injectable, Input, Component, Output, ContentChildren, QueryList, ViewChild, ElementRef, TemplateRef, ViewContainerRef, ViewChildren, HostBinding, HostListener, ChangeDetectorRef, NgZone, Renderer2, forwardRef, Directive, NgModule } from '@angular/core';
import { PopupService, PopupModule } from '@progress/kendo-angular-popup';
import { Keys, isDocumentAvailable, ResizeSensorComponent, ResizeSensorModule } from '@progress/kendo-angular-common';
import { LocalizationService, L10N_PREFIX, ComponentMessages } from '@progress/kendo-angular-l10n';
import { validatePackage } from '@progress/kendo-licensing';
import { Subject, Subscription, merge, fromEvent } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ButtonGroupComponent, DropDownButtonComponent, SplitButtonComponent, ButtonsModule } from '@progress/kendo-angular-buttons';
import { CommonModule } from '@angular/common';

/**
 * @hidden
 */
const packageMetadata = {
    name: '@progress/kendo-angular-toolbar',
    productName: 'Kendo UI for Angular',
    productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
    publishDate: 1620216314,
    version: '',
    licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/?utm_medium=product&utm_source=kendoangular&utm_campaign=kendo-ui-angular-purchase-license-keys-warning'
};

/**
 * @hidden
 */
let RefreshService = class RefreshService {
    /**
     * @hidden
     */
    constructor() {
        this.onRefresh = new EventEmitter();
    }
    refresh(tool) {
        this.onRefresh.emit(tool);
    }
};
RefreshService = __decorate([
    Injectable()
], RefreshService);

/**
 * @hidden
 */
const focusableRegex = /^(?:a|input|select|textarea|button|object)$/i;
/**
 * @hidden
 */
function outerWidth(element) {
    var width = element.offsetWidth;
    var style = getComputedStyle(element);
    width += parseFloat(style.marginLeft) || 0 + parseFloat(style.marginRight) || 0;
    return width;
}
/**
 * @hidden
 */
function innerWidth(element) {
    var width = element.offsetWidth;
    var style = getComputedStyle(element);
    width -= parseFloat(style.paddingLeft) || 0 + parseFloat(style.borderLeftWidth) || 0;
    width -= parseFloat(style.paddingRight) || 0 + parseFloat(style.borderRightWidth) || 0;
    return width;
}
/**
 * @hidden
 */
const isVisible = (element) => {
    const rect = element.getBoundingClientRect();
    const hasSize = rect.width > 0 && rect.height > 0;
    const hasPosition = rect.x !== 0 && rect.y !== 0;
    // Elements can have zero size due to styling, but they should still count as visible.
    // For example, the selection checkbox has no size, but is made visible through styling.
    return (hasSize || hasPosition) && window.getComputedStyle(element).visibility !== 'hidden';
};
/**
 * @hidden
 */
const findElement = (node, predicate, matchSelf = true) => {
    if (!node) {
        return;
    }
    if (matchSelf && predicate(node)) {
        return node;
    }
    node = node.firstChild;
    while (node) {
        if (node.nodeType === 1) {
            const element = findElement(node, predicate);
            if (element) {
                return element;
            }
        }
        node = node.nextSibling;
    }
};
/**
 * @hidden
 */
const isFocusable = (element, checkVisibility = true) => {
    if (element.tagName) {
        const tagName = element.tagName.toLowerCase();
        const tabIndex = element.getAttribute('tabIndex');
        let focusable = tabIndex !== null;
        if (focusableRegex.test(tagName)) {
            focusable = !element.disabled;
        }
        return focusable && (!checkVisibility || isVisible(element));
    }
    return false;
};
/**
 * @hidden
 */
const findFocusable = (element, checkVisibility = true) => {
    return findElement(element, node => isFocusable(node, checkVisibility));
};
/**
 * @hidden
 */
const findFocusableChild = (element, checkVisibility = true) => {
    return findElement(element, node => isFocusable(node, checkVisibility), false);
};
/**
 * @hidden
 */
const findFocusableSibling = (element, checkVisibility = true, reverse) => {
    let node = reverse ? element.prevSibling : element.nextSibling;
    while (node) {
        if (node.nodeType === 1) {
            const result = findElement(node, el => isFocusable(el, checkVisibility));
            if (result) {
                return result;
            }
        }
        node = reverse ? node.prevSibling : node.nextSibling;
    }
};
/**
 * @hidden
 */
const isPresent = (value) => value !== null && value !== undefined;
/**
 * @hidden
 */
const makePeeker = (collection) => (index) => isPresent(collection[index]);
/**
 * @hidden
 */
const getIndexOfFocused = (prevKeyCode, nextKeyCode, collection) => (ev) => {
    switch (ev.type) {
        case 'keydown':
            if (ev.keyCode === prevKeyCode) {
                return collection.length - 1;
            }
            if (ev.keyCode === nextKeyCode) {
                return 0;
            }
            break;
        case 'click':
            return collection.findIndex(be => be === ev.target || be.contains(ev.target));
        case 'focus':
            return 0;
        default:
            return 0;
    }
};
/**
 * @hidden
 */
const seekFocusedIndex = (prevKeyCode, nextKeyCode, seeker) => (startIndex, ev) => {
    switch (ev.keyCode) {
        case prevKeyCode:
            return seeker(startIndex - 1) ? startIndex - 1 : startIndex;
        case nextKeyCode:
            return seeker(startIndex + 1) ? startIndex + 1 : startIndex;
        default:
            return startIndex;
    }
};
/**
 * @hidden
 */
const areEqual = (first) => (second) => first === second;
/**
 * @hidden
 */
const getNextKey = (rtl = false) => (overflows = true) => overflows ? Keys.ArrowDown : rtl ? Keys.ArrowLeft : Keys.ArrowRight;
/**
 * @hidden
 */
const getPrevKey = (rtl = false) => (overflows = true) => overflows ? Keys.ArrowUp : rtl ? Keys.ArrowRight : Keys.ArrowLeft;
/**
 * @hidden
 */
const getValueForLocation = (property, displayMode, overflows) => {
    switch (displayMode) {
        case 'toolbar':
            return overflows ? undefined : property;
        case 'overflow':
            return overflows ? property : undefined;
        default:
            return property;
    }
};

/**
 * @hidden
 */
let NavigationService = class NavigationService {
    /**
     * @hidden
     */
    constructor() {
        this.focused = { renderedTool: null, index: -1 };
        this.renderedTools = [];
        this.isPopupFocused = false;
        this.isOverflowButtonFocused = false;
    }
    setRenderedTools(rts) {
        this.renderedTools = [...rts];
    }
    click({ context, event: ev }) {
        if (this.focused.renderedTool !== context && ev) {
            this.focus(context, ev);
        }
    }
    moveFocusToToolBar() {
        this.isPopupFocused = false;
        this.focusOverflowButton();
    }
    moveFocusToPopup() {
        this.isPopupFocused = true;
        this.blurOverflowButton();
        this.focus();
    }
    focusNext(ev) {
        if (!this.isOverflowButtonFocused && this.focused.renderedTool && !this.focused.renderedTool.tool.handleKey(ev)) {
            const nextRT = this.getFocusableTools().slice(this.focused.index + 1)[0];
            if (nextRT) {
                this.focus(nextRT, ev);
            }
            else {
                if (this.isOverflowButtonVisible() && !this.isPopupFocused) {
                    this.focusOverflowButton();
                }
            }
        }
    }
    focusPrev(ev) {
        if (this.isOverflowButtonFocused) {
            const lastFocusableRT = this.getFocusableTools().reverse()[0];
            this.focus(lastFocusableRT, ev);
        }
        else if (this.focused.renderedTool && !this.focused.renderedTool.tool.handleKey(ev)) {
            const prevRT = this.getFocusableTools()
                .slice(0, this.focused.index)
                .reverse()[0];
            if (prevRT) {
                this.focus(prevRT, ev);
            }
        }
    }
    resetNavigation() {
        this.blurOverflowButton();
        this.focused.renderedTool = null;
        this.focused.index = -1;
    }
    focusFirst(ev) {
        const firstTool = this.getFocusableTools()[0];
        if (firstTool) {
            this.focused.renderedTool = firstTool;
            this.focused.index = this.getFocusableTools().findIndex(rt => rt === firstTool);
            this.focus(firstTool, ev);
        }
    }
    getFocusableTools() {
        return this.renderedTools.filter(rt => rt.tool.overflows === this.isPopupFocused && rt.tool.canFocus());
    }
    focus(renderedTool, ev) {
        if (!renderedTool) {
            const focusableRTs = this.getFocusableTools();
            const lastFocusedRT = focusableRTs.find(rt => rt === this.focused.renderedTool) || focusableRTs[0];
            // guard against only disabled tools
            if (lastFocusedRT) {
                this.focused.renderedTool = lastFocusedRT;
                this.focused.index = this.getFocusableTools().findIndex(rt => rt === lastFocusedRT);
                // if event is undefined, then this means that the tool is the first one in the overflow popup
                lastFocusedRT.tool.focus(ev);
            }
        }
        else if (renderedTool.tool.canFocus && renderedTool.tool.canFocus()) {
            this.focused.renderedTool = renderedTool;
            this.focused.index = this.getFocusableTools().findIndex(rt => rt === renderedTool);
            renderedTool.tool.focus(ev);
            this.blurOverflowButton();
        }
    }
    blurOverflowButton() {
        if (this.overflowButton) {
            this.isOverflowButtonFocused = false;
            this.overflowButton.nativeElement.tabIndex = -1;
        }
    }
    focusOverflowButton() {
        this.isOverflowButtonFocused = true;
        this.overflowButton.nativeElement.tabIndex = 0;
        this.overflowButton.nativeElement.focus();
    }
    isOverflowButtonVisible() {
        return (isPresent(this.overflowButton) &&
            window.getComputedStyle(this.overflowButton.nativeElement).getPropertyValue('visibility') === 'visible');
    }
};
NavigationService = __decorate([
    Injectable()
], NavigationService);

/**
 * Represents the Base ToolBar Tool component for Angular.
 * Extend this class to create custom tools.
 */
let ToolBarToolComponent = class ToolBarToolComponent {
    /**
     * Represents the Base ToolBar Tool component for Angular.
     * Extend this class to create custom tools.
     */
    constructor() {
        this.tabIndex = -1; //Focus movement inside the toolbar is managed using roving tabindex.
        this.overflows = true;
        // this should be replaced with showTool: DisplayMode = 'both';
        /**
         * @hidden
         */
        this.responsive = true;
    }
    get toolbarDisplay() {
        return this.overflows ? 'none' : 'inline-block';
    }
    get overflowDisplay() {
        return this.overflows ? 'block' : 'none';
    }
    /**
     * Determines if the tool can be focused.
     * If the returned value is `false`, the tool will not be part of the keyboard navigation.
     * @returns `true` if the tool should take part in keyboard navigation.
     */
    canFocus() {
        return false;
    }
    /**
     * Called when the tool is focused.
     * The method accepts as argument the original browser event, which can be a `KeyboardEvent`, `MouseEvent` or `FocusEvent`.
     * @param {Event} ev - This is the event that caused the tool to be focused.
     */
    // @ts-ignore
    focus(ev) {
        /* noop */
    }
    /**
     * Called when the tool is focused and one of the arrow keys is pressed.
     * The returned boolean value determines whether the `ToolBarComponent` will move the focus to the next/previous `ToolBarToolComponent`
     * ([see example]({% slug customcontroltypes_toolbar %}#toc-adding-keyboard-navigation)).
     * @param {KeyboardEvent} ev - The last pressed arrow key
     * @returns
     */
    // @ts-ignore
    handleKey(ev) {
        return false;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ToolBarToolComponent.prototype, "responsive", void 0);
ToolBarToolComponent = __decorate([
    Component({
        // tslint:disable-next-line:component-selector
        selector: 'toolbar-tool',
        template: ``
    })
], ToolBarToolComponent);

/**
 * @hidden
 */
class PreventableEvent {
    constructor() {
        this.prevented = false;
    }
    /**
     * Prevents the default action for a specified event.
     * In this way, the source component suppresses the built-in behavior that follows the event.
     */
    preventDefault() {
        this.prevented = true;
    }
    /**
     * If the event is prevented by any of its subscribers, returns `true`.
     *
     * @returns `true` if the default action was prevented. Otherwise, returns `false`.
     */
    isDefaultPrevented() {
        return this.prevented;
    }
}

/**
 * @hidden
 */
const defaultPopupWidth = '150px';

const getInitialPopupSettings = (isRtl) => ({
    animate: true,
    anchorAlign: { horizontal: isRtl ? 'left' : 'right', vertical: 'bottom' },
    popupAlign: { horizontal: isRtl ? 'left' : 'right', vertical: 'top' }
});
/**
 * Represents the [Kendo UI ToolBar component for Angular]({% slug overview_toolbar %}).
 */
let ToolBarComponent = class ToolBarComponent {
    constructor(localization, popupService, refreshService, navigationService, element, cdr, zone, renderer) {
        this.localization = localization;
        this.popupService = popupService;
        this.refreshService = refreshService;
        this.navigationService = navigationService;
        this.element = element;
        this.cdr = cdr;
        this.zone = zone;
        this.renderer = renderer;
        /**
         * Hides the overflowing tools in a popup.
         */
        this.overflow = false;
        /**
         * Specifies the [`tabindex`](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex) of the ToolBar.
         */
        this.tabindex = 0;
        /**
         * Fires when the overflow popup of the ToolBar is opened.
         */
        this.open = new EventEmitter();
        /**
         * Fires when the overflow popup of the ToolBar is closed.
         */
        this.close = new EventEmitter();
        this.hostClasses = true;
        this.cancelRenderedToolsSubscription$ = new Subject();
        this.subscriptions = new Subscription();
        validatePackage(packageMetadata);
        this.direction = localization.rtl ? 'rtl' : 'ltr';
    }
    /**
     * @hidden
     */
    set resizable(value) {
        this.overflow = value;
    }
    get resizable() {
        return this.overflow;
    }
    /**
     * Configures the popup of the ToolBar drop-down list.
     *
     * The available options are:
     * - `animate: Boolean`&mdash;Controls the popup animation. By default, the open and close animations are enabled.
     * - `popupClass: String`&mdash;Specifies a list of CSS classes that are used to style the popup.
     */
    set popupSettings(settings) {
        this._popupSettings = Object.assign({}, getInitialPopupSettings(this.localization.rtl), settings);
    }
    get popupSettings() {
        return this._popupSettings || getInitialPopupSettings(this.localization.rtl);
    }
    /**
     * @hidden
     */
    set tabIndex(tabIndex) {
        this.tabindex = tabIndex;
    }
    get tabIndex() {
        return this.tabindex;
    }
    get appendTo() {
        const { appendTo } = this.popupSettings;
        if (!appendTo || appendTo === 'root') {
            return undefined;
        }
        return appendTo === 'component' ? this.container : appendTo;
    }
    set popupOpen(open) {
        if (this.popupOpen === open) {
            return;
        }
        const eventArgs = new PreventableEvent();
        if (open) {
            this.open.emit(eventArgs);
        }
        else {
            this.close.emit(eventArgs);
        }
        if (eventArgs.isDefaultPrevented()) {
            return;
        }
        this.toggle(open);
    }
    get popupOpen() {
        return this._open;
    }
    /**
     * @hidden
     */
    onFocus(ev) {
        this.navigationService.resetNavigation();
        this.navigationService.focusFirst(ev);
        // prevents ExpressionChangedAfterItHasBeenCheckedError when tools with popup are opened/closed asynchronously
        this.element.nativeElement.setAttribute('tabindex', '-1');
    }
    /**
     * @hidden
     */
    onFocusOut() {
        // prevents ExpressionChangedAfterItHasBeenCheckedError when tools with popup are opened/closed asynchronously
        this.element.nativeElement.setAttribute('tabindex', this.tabindex.toString());
    }
    get getRole() {
        return 'toolbar';
    }
    get getDir() {
        return this.direction;
    }
    get resizableClass() {
        return this.overflow;
    }
    ngAfterViewInit() {
        const element = this.element.nativeElement;
        if (!element.getAttribute('tabindex')) {
            this.element.nativeElement.setAttribute('tabindex', '0');
        }
        this.zone.runOutsideAngular(() => {
            this.toolbarKeydownListener = this.renderer.listen(this.element.nativeElement, 'keydown', (ev) => {
                switch (ev.keyCode) {
                    case Keys.ArrowLeft:
                        this.zone.run(() => {
                            ev.preventDefault();
                            this.direction === 'ltr' ? this.navigationService.focusPrev(ev) : this.navigationService.focusNext(ev);
                            // prevents ExpressionChangedAfterItHasBeenCheckedError when tools with popup are opened/closed asynchronously
                            this.element.nativeElement.setAttribute('tabindex', '-1');
                        });
                        break;
                    case Keys.ArrowRight:
                        this.zone.run(() => {
                            ev.preventDefault();
                            this.direction === 'ltr' ? this.navigationService.focusNext(ev) : this.navigationService.focusPrev(ev);
                            // prevents ExpressionChangedAfterItHasBeenCheckedError when tools with popup are opened/closed asynchronously
                            this.element.nativeElement.setAttribute('tabindex', '-1');
                        });
                        break;
                    case Keys.Tab:
                        this.zone.run(() => this.navigationService.resetNavigation());
                        break;
                    case Keys.Escape:
                        this.zone.run(() => this.toggle(false));
                        break;
                    default:
                        break;
                }
            });
        });
        if (this.overflow) {
            this.subscriptions.add(merge(this.resizeSensor.resize.pipe(filter(() => this.overflow)), this.renderedTools.changes).subscribe(() => this.onResize()));
            // because of https://github.com/telerik/kendo-angular-buttons/pull/276
            this.zone.runOutsideAngular(() => setTimeout(() => this.onResize()));
            this.navigationService.overflowButton = this.overflowButton;
        }
        this.navigationService.setRenderedTools(this.renderedTools.toArray());
        this.subscriptions.add(this.renderedTools.changes.subscribe((rts) => this.navigationService.setRenderedTools(rts.toArray())));
    }
    ngOnInit() {
        this.subscriptions.add(this.localization.changes.subscribe(({ rtl }) => (this.direction = rtl ? 'rtl' : 'ltr')));
        if (isDocumentAvailable()) {
            this.zone.runOutsideAngular(() => this.subscriptions.add(fromEvent(document, 'click')
                .pipe(filter(() => !!this.popupRef), filter((ev) => !this.popupRef.popup.instance.container.nativeElement.contains(ev.target)), filter((ev) => !this.overflowButton.nativeElement.contains(ev.target)))
                .subscribe(() => {
                this.zone.run(() => {
                    this.popupOpen = false;
                });
            })));
        }
    }
    ngOnChanges(changes) {
        if (changes.tabindex) {
            // prevents ExpressionChangedAfterItHasBeenCheckedError when tools with popup are opened/closed asynchronously
            this.element.nativeElement.setAttribute('tabindex', changes.tabindex.currentValue.toString());
        }
    }
    ngOnDestroy() {
        if (this.popupRef) {
            this.popupRef.close();
        }
        if (this.toolbarKeydownListener) {
            this.toolbarKeydownListener();
        }
        this.cancelRenderedToolsSubscription$.next();
        this.subscriptions.unsubscribe();
    }
    /**
     * @hidden
     */
    get moreToolsTitle() {
        return this.localization.get('moreToolsTitle');
    }
    /**
     * @hidden
     */
    onRendererClick(data) {
        this.navigationService.click(data);
        this.element.nativeElement.setAttribute('tabindex', '-1');
    }
    /**
     * @hidden
     */
    showPopup() {
        this.popupOpen = !this.popupOpen;
        this.navigationService.click({ context: undefined, event: undefined });
    }
    /**
     * Toggles the visibility of the overflow popup.
     */
    toggle(popupOpen) {
        this._open = popupOpen !== undefined ? popupOpen : !this.popupOpen;
        if (this.popupRef) {
            this.popupRef.close();
            this.popupRef = null;
        }
        if (this.popupOpen) {
            this.popupRef = this.popupService.open({
                anchor: this.overflowButton,
                anchorAlign: this.popupSettings.anchorAlign,
                popupAlign: this.popupSettings.popupAlign,
                content: this.popupTemplate,
                appendTo: this.appendTo,
                animate: this.popupSettings.animate,
                popupClass: this.popupSettings.popupClass,
                positionMode: 'absolute'
            });
            this.setPopupContentDimensions();
            this.popupRef.popupOpen.subscribe(this.onPopupOpen.bind(this));
            this.popupRef.popupClose.subscribe(this.onPopupClose.bind(this));
        }
    }
    /**
     * @hidden
     */
    onResize() {
        if (isDocumentAvailable()) {
            const containerWidth = innerWidth(this.element.nativeElement) - this.overflowAnchorWidth;
            this.shrink(containerWidth, this.childrenWidth);
            this.stretch(containerWidth, this.childrenWidth);
        }
        this.cdr.detectChanges();
        this.resizeSensor.acceptSize();
    }
    /**
     * @hidden
     */
    onPopupOpen() {
        this.zone.runOutsideAngular(() => {
            this.overflowKeydownListener = this.renderer.listen(this.popupRef.popupElement, 'keydown', (ev) => {
                switch (ev.keyCode) {
                    case Keys.ArrowUp:
                        this.zone.run(() => {
                            ev.preventDefault();
                            this.navigationService.focusPrev(ev);
                        });
                        break;
                    case Keys.ArrowDown:
                        this.zone.run(() => {
                            ev.preventDefault();
                            this.navigationService.focusNext(ev);
                        });
                        break;
                    case Keys.Escape:
                        this.zone.run(() => this.toggle(false));
                        break;
                    case Keys.Tab:
                        this.zone.run(() => {
                            this.toggle(false);
                            this.navigationService.resetNavigation();
                        });
                        break;
                    default:
                        break;
                }
            });
        });
        this.cancelRenderedToolsSubscription$.next();
        this.navigationService.moveFocusToPopup();
        this.navigationService.setRenderedTools(this.overflowRenderedTools.toArray());
        this.overflowRenderedTools.changes
            .pipe(takeUntil(this.cancelRenderedToolsSubscription$))
            .subscribe((rts) => this.navigationService.setRenderedTools(rts.toArray()));
    }
    /**
     * @hidden
     */
    onPopupClose() {
        this.cancelRenderedToolsSubscription$.next();
        this.navigationService.setRenderedTools(this.renderedTools.toArray());
        this.renderedTools.changes
            .pipe(takeUntil(this.cancelRenderedToolsSubscription$))
            .subscribe((rts) => this.navigationService.setRenderedTools(rts.toArray()));
        this.navigationService.moveFocusToToolBar();
        if (this.overflowKeydownListener) {
            this.overflowKeydownListener();
        }
    }
    get displayAnchor() {
        return this.allTools.filter(t => t.overflows && t.responsive).length > 0 ? 'visible' : 'hidden';
    }
    get popupWidth() {
        if (!this.popupSettings || !this.popupSettings.width) {
            return defaultPopupWidth;
        }
        return isNaN(this.popupSettings.width) ? this.popupSettings.width : `${this.popupSettings.width}px`;
    }
    get popupHeight() {
        if (!this.popupSettings || !this.popupSettings.height) {
            return;
        }
        return isNaN(this.popupSettings.height) ? this.popupSettings.height : `${this.popupSettings.height}px`;
    }
    get overflowAnchorWidth() {
        if (!this.overflow) {
            return 0;
        }
        if (!this.cachedOverflowAnchorWidth) {
            this.cachedOverflowAnchorWidth = outerWidth(this.overflowButton.nativeElement);
        }
        return this.cachedOverflowAnchorWidth;
    }
    get childrenWidth() {
        const width = this.renderedTools.reduce((totalWidth, tool) => tool.width + totalWidth, 0);
        return Math.ceil(width);
    }
    get visibleTools() {
        return this.allTools.filter((tool) => {
            return tool.overflows === false;
        });
    }
    get overflowTools() {
        return this.allTools.filter((tool) => {
            return tool.overflows === true;
        });
    }
    shrink(containerWidth, childrenWidth) {
        let width;
        if (containerWidth < childrenWidth) {
            for (var i = this.visibleTools.length - 1; i >= 0; i--) {
                if (containerWidth > childrenWidth) {
                    break;
                }
                else {
                    width = this.hideLastVisibleTool();
                    childrenWidth -= width;
                }
            }
        }
    }
    stretch(containerWidth, childrenWidth) {
        let width;
        if (containerWidth > childrenWidth) {
            for (var i = this.overflowTools.length - 1; i >= 0; i--) {
                width = this.showFirstHiddenTool(containerWidth, childrenWidth);
                if (width) {
                    childrenWidth += width;
                }
                else {
                    break;
                }
            }
        }
    }
    hideLastVisibleTool() {
        const tool = this.visibleTools[this.visibleTools.length - 1];
        const renderedElement = this.renderedTools.find((r) => {
            return r.tool === tool;
        });
        const width = renderedElement.width;
        tool.overflows = true;
        this.refreshService.refresh(tool);
        return width;
    }
    showFirstHiddenTool(containerWidth, childrenWidth) {
        const tool = this.overflowTools[0];
        const renderedElement = this.renderedTools.find(r => r.tool === tool);
        tool.overflows = false;
        tool.visibility = 'hidden';
        this.refreshService.refresh(tool);
        if (containerWidth > childrenWidth + renderedElement.width) {
            tool.visibility = 'visible';
            this.refreshService.refresh(tool);
        }
        else {
            tool.overflows = true;
            this.refreshService.refresh(tool);
        }
        return renderedElement.width; // returns 0 if `overflows` is true
    }
    setPopupContentDimensions() {
        const popupContentContainer = this.popupRef.popup.instance.contentContainer.nativeElement;
        popupContentContainer.style.width = this.popupWidth;
        popupContentContainer.style.height = this.popupHeight;
        popupContentContainer.style.overflow = 'auto';
    }
};
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ToolBarComponent.prototype, "overflow", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], ToolBarComponent.prototype, "resizable", null);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], ToolBarComponent.prototype, "popupSettings", null);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ToolBarComponent.prototype, "tabindex", void 0);
__decorate([
    Input('tabIndex'),
    __metadata("design:type", Number),
    __metadata("design:paramtypes", [Number])
], ToolBarComponent.prototype, "tabIndex", null);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ToolBarComponent.prototype, "open", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ToolBarComponent.prototype, "close", void 0);
__decorate([
    ContentChildren(ToolBarToolComponent),
    __metadata("design:type", QueryList)
], ToolBarComponent.prototype, "allTools", void 0);
__decorate([
    ViewChild('overflowButton', { static: false }),
    __metadata("design:type", ElementRef)
], ToolBarComponent.prototype, "overflowButton", void 0);
__decorate([
    ViewChild('popupTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], ToolBarComponent.prototype, "popupTemplate", void 0);
__decorate([
    ViewChild('resizeSensor', { static: false }),
    __metadata("design:type", ResizeSensorComponent)
], ToolBarComponent.prototype, "resizeSensor", void 0);
__decorate([
    ViewChild('container', { read: ViewContainerRef, static: true }),
    __metadata("design:type", ViewContainerRef)
], ToolBarComponent.prototype, "container", void 0);
__decorate([
    ViewChildren('toolbarRenderer'),
    __metadata("design:type", QueryList)
], ToolBarComponent.prototype, "renderedTools", void 0);
__decorate([
    ViewChildren('overflowRenderer'),
    __metadata("design:type", QueryList)
], ToolBarComponent.prototype, "overflowRenderedTools", void 0);
__decorate([
    HostBinding('class.k-widget'),
    HostBinding('class.k-toolbar'),
    __metadata("design:type", Boolean)
], ToolBarComponent.prototype, "hostClasses", void 0);
__decorate([
    HostListener('focus', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ToolBarComponent.prototype, "onFocus", null);
__decorate([
    HostListener('focusout'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ToolBarComponent.prototype, "onFocusOut", null);
__decorate([
    HostBinding('attr.role'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], ToolBarComponent.prototype, "getRole", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], ToolBarComponent.prototype, "getDir", null);
__decorate([
    HostBinding('class.k-toolbar-resizable'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ToolBarComponent.prototype, "resizableClass", null);
ToolBarComponent = __decorate([
    Component({
        exportAs: 'kendoToolBar',
        providers: [
            RefreshService,
            NavigationService,
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.toolbar'
            }
        ],
        selector: 'kendo-toolbar',
        template: `
        <ng-container kendoToolbarLocalizedMessages
        i18n-moreToolsTitle="kendo.toolbar.moreToolsTitle|The title of the **more tools** button in a responsive ToolBar"
        moreToolsTitle="More tools"
        >
        </ng-container>
        <ng-container *ngFor="let tool of allTools; let index = index">
            <kendo-toolbar-renderer
                #toolbarRenderer
                (rendererClick)="onRendererClick($event)"
                [location]="'toolbar'"
                [resizable]="overflow"
                [tool]="tool"
            ></kendo-toolbar-renderer>
        </ng-container>
        <button
            #overflowButton
            type="button"
            tabindex="-1"
            [title]="moreToolsTitle"
            [attr.aria-label]="moreToolsTitle"
            *ngIf="overflow"
            [style.visibility]="displayAnchor"
            class="k-overflow-anchor k-button"
            (click)="showPopup()"
        >
            <span class="k-icon k-i-more-vertical"></span>
        </button>
        <ng-template #popupTemplate>
            <ul class="k-overflow-container k-list-container k-reset">
                <ng-container *ngFor="let tool of allTools; let index = index">
                    <li class="k-item">
                        <kendo-toolbar-renderer
                            #overflowRenderer
                            (rendererClick)="onRendererClick($event)"
                            [location]="'overflow'"
                            [resizable]="overflow"
                            [tool]="tool"
                        ></kendo-toolbar-renderer>
                    </li>
                </ng-container>
            </ul>
        </ng-template>
        <ng-container #container></ng-container>
        <kendo-resize-sensor *ngIf="overflow" [rateLimit]="1000" #resizeSensor></kendo-resize-sensor>
    `
    }),
    __metadata("design:paramtypes", [LocalizationService,
        PopupService,
        RefreshService,
        NavigationService,
        ElementRef,
        ChangeDetectorRef,
        NgZone,
        Renderer2])
], ToolBarComponent);

var ToolBarButtonComponent_1;
/**
 * Represents the [Kendo UI ToolBar Button tool for Angular]({% slug controltypes_toolbar %}#toc-buttons).
 */
let ToolBarButtonComponent = ToolBarButtonComponent_1 = class ToolBarButtonComponent extends ToolBarToolComponent {
    constructor() {
        super();
        // showText and showIcon showIcon should be declared first
        /**
         * Specifies where button text should be displayed
         */
        this.showText = 'both';
        /**
         * Specifies where button icon should be displayed
         */
        this.showIcon = 'both';
        /**
         * Provides visual styling that indicates if the Button is active
         * ([see example]({% slug controltypes_toolbar %}#toc-toggle-buttons)).
         * By default, `toggleable` is set to `false`.
         */
        this.toggleable = false;
        /**
         * Adds visual weight to the Button and makes it primary
         * ([see example]({% slug controltypes_toolbar %}#toc-buttons)).
         */
        this.primary = false;
        /**
         * Changes the visual appearance by using alternative styling options
         * ([see example]({% slug controltypes_toolbar %}#toc-buttons)).
         *
         * The available values are:
         * * `bare`
         * * `flat`
         * * `outline`
         */
        this.look = 'default';
        /**
         * Sets the selected state of the Button.
         */
        this.selected = false;
        /**
         * Fires each time the Button is clicked.
         */
        this.click = new EventEmitter();
        /**
         * Fires each time the selected state of a Toggle Button is changed.
         * The event argument is the new selected state (Boolean).
         */
        this.selectedChange = new EventEmitter();
        this.toolbarOptions = {
            text: '',
            icon: '',
            iconClass: '',
            imageUrl: ''
        };
        this.overflowOptions = {
            text: '',
            icon: '',
            iconClass: '',
            imageUrl: ''
        };
    }
    /**
     * Specifies the text of the Button ([see example]({% slug controltypes_toolbar %}#toc-buttons)).
     */
    set text(text) {
        this.toolbarOptions.text = getValueForLocation(text, this.showText, false);
        this.overflowOptions.text = getValueForLocation(text, this.showText, true);
    }
    /**
     * @hidden
     */
    get togglable() {
        return this.toggleable;
    }
    set togglable(value) {
        this.toggleable = value;
    }
    /**
     * Defines the name for an existing icon in a Kendo UI theme
     * ([see example]({% slug controltypes_toolbar %}#toc-buttons)).
     * The icon is rendered inside the Button by a `span.k-icon` element.
     */
    set icon(icon) {
        this.toolbarOptions.icon = getValueForLocation(icon, this.showIcon, false);
        this.overflowOptions.icon = getValueForLocation(icon, this.showIcon, true);
    }
    /**
     * Defines a CSS class&mdash;or multiple classes separated by spaces&mdash;
     * which are applied to a `span` element inside the Button. Allows the usage of custom icons.
     */
    set iconClass(iconClass) {
        this.toolbarOptions.iconClass = getValueForLocation(iconClass, this.showIcon, false);
        this.overflowOptions.iconClass = getValueForLocation(iconClass, this.showIcon, true);
    }
    /**
     * Defines a URL which is used for an `img` element inside the Button.
     * The URL can be relative or absolute. If relative, it is evaluated with relation to the web page URL.
     */
    set imageUrl(imageUrl) {
        this.toolbarOptions.imageUrl = getValueForLocation(imageUrl, this.showIcon, false);
        this.overflowOptions.imageUrl = getValueForLocation(imageUrl, this.showIcon, true);
    }
    /**
     * @hidden
     */
    onBlur() {
        this.getButton().tabIndex = -1;
    }
    /**
     * @hidden
     */
    canFocus() {
        return !this.disabled;
    }
    /**
     * @hidden
     */
    focus(ev) {
        // guard against focusing twice on mousedown.
        if (!ev || ev.type === 'focus' || ev.type === 'keydown') {
            this.getButton().focus();
        }
        this.getButton().tabIndex = 0;
    }
    /**
     * @hidden
     */
    handleKey() {
        this.getButton().tabIndex = -1;
        return false;
    }
    getButton() {
        return (this.overflows ? this.overflowButtonElement : this.toolbarButtonElement).nativeElement;
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], ToolBarButtonComponent.prototype, "showText", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ToolBarButtonComponent.prototype, "showIcon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], ToolBarButtonComponent.prototype, "text", null);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ToolBarButtonComponent.prototype, "style", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ToolBarButtonComponent.prototype, "className", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ToolBarButtonComponent.prototype, "title", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ToolBarButtonComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ToolBarButtonComponent.prototype, "toggleable", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], ToolBarButtonComponent.prototype, "togglable", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ToolBarButtonComponent.prototype, "primary", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ToolBarButtonComponent.prototype, "look", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ToolBarButtonComponent.prototype, "selected", void 0);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], ToolBarButtonComponent.prototype, "icon", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], ToolBarButtonComponent.prototype, "iconClass", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], ToolBarButtonComponent.prototype, "imageUrl", null);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ToolBarButtonComponent.prototype, "click", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ToolBarButtonComponent.prototype, "selectedChange", void 0);
__decorate([
    ViewChild('toolbarTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], ToolBarButtonComponent.prototype, "toolbarTemplate", void 0);
__decorate([
    ViewChild('popupTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], ToolBarButtonComponent.prototype, "popupTemplate", void 0);
__decorate([
    ViewChild('toolbarButton', { read: ElementRef, static: false }),
    __metadata("design:type", ElementRef)
], ToolBarButtonComponent.prototype, "toolbarButtonElement", void 0);
__decorate([
    ViewChild('overflowButton', { read: ElementRef, static: false }),
    __metadata("design:type", ElementRef)
], ToolBarButtonComponent.prototype, "overflowButtonElement", void 0);
ToolBarButtonComponent = ToolBarButtonComponent_1 = __decorate([
    Component({
        exportAs: 'kendoToolBarButton',
        // tslint:disable-next-line:no-forward-ref
        providers: [{ provide: ToolBarToolComponent, useExisting: forwardRef(() => ToolBarButtonComponent_1) }],
        selector: 'kendo-toolbar-button',
        template: `
        <ng-template #toolbarTemplate>
            <button
                #toolbarButton
                [tabindex]="tabIndex"
                type="button"
                kendoButton
                [ngStyle]="style"
                [ngClass]="className"
                [attr.title]="title"
                [disabled]="disabled"
                [toggleable]="toggleable"
                [primary]="primary"
                [selected]="selected"
                [icon]="toolbarOptions.icon"
                [iconClass]="toolbarOptions.iconClass"
                [imageUrl]="toolbarOptions.imageUrl"
                [look]="look"
                (click)="click.emit($event)"
                (selectedChange)="selectedChange.emit($event)"
                (blur)="onBlur()"
            >
                {{ toolbarOptions.text }}
            </button>
        </ng-template>
        <ng-template #popupTemplate>
            <button
                #overflowButton
                tabindex="-1"
                type="button"
                kendoButton
                class="k-overflow-button"
                [ngStyle]="style"
                [ngClass]="className"
                [attr.title]="title"
                [disabled]="disabled"
                [toggleable]="toggleable"
                [primary]="primary"
                [selected]="selected"
                [icon]="overflowOptions.icon"
                [iconClass]="overflowOptions.iconClass"
                [imageUrl]="overflowOptions.imageUrl"
                [look]="look"
                (click)="click.emit($event)"
                (selectedChange)="selectedChange.emit($event)"
            >
                {{ overflowOptions.text }}
            </button>
        </ng-template>
    `
    }),
    __metadata("design:paramtypes", [])
], ToolBarButtonComponent);

var ToolBarButtonGroupComponent_1;
/**
 * Represents the Kendo UI Toolbar ButtonGroup for Angular.
 */
let ToolBarButtonGroupComponent = ToolBarButtonGroupComponent_1 = class ToolBarButtonGroupComponent extends ToolBarToolComponent {
    constructor(localization) {
        super();
        this.localization = localization;
        /**
         * By default, the selection mode of the ButtonGroup is set to `multiple`.
         */
        this.selection = 'multiple';
        /**
         * Changes the visual appearance by using alternative styling options.
         * The `look` property of the ButtonGroup takes precedence over the `look` property
         * of the individual buttons that are part of the group.
         *
         * The available values are:
         * * `bare`
         * * `flat`
         * * `outline`
         */
        this.look = 'default';
        this.focusedIndex = -1;
        this.getNextKey = getNextKey(this.localization.rtl);
        this.getPrevKey = getPrevKey(this.localization.rtl);
    }
    get buttonElements() {
        return this.getButtonGroup()
            .buttons.filter(b => !b.isDisabled)
            .map(b => b.element);
    }
    /**
     * @hidden
     */
    onFocus() {
        // triggered on tabin
        this.focusedIndex = 0;
    }
    /**
     * @hidden
     */
    onNavigate(ev) {
        ev.preventDefault();
    }
    /**
     * @hidden
     */
    selectedChangeHandler(state, button) {
        button.selected = state;
        button.selectedChange.emit(state);
    }
    /**
     * @hidden
     */
    onButtonClick(ev) {
        this.focusedIndex = this.buttonElements.findIndex(be => be === ev.target || be.contains(ev.target));
        this.focusButton(this.focusedIndex, ev);
    }
    /**
     * @hidden
     */
    canFocus() {
        return !(this.disabled || this.buttonElements.length === 0);
    }
    /**
     * @hidden
     */
    focus(ev = {}) {
        const nextKey = this.getNextKey(this.overflows);
        const prevKey = this.getPrevKey(this.overflows);
        this.focusedIndex = getIndexOfFocused(prevKey, nextKey, this.buttonElements)(ev);
        this.focusButton(this.focusedIndex, ev);
    }
    /**
     * @hidden
     */
    handleKey(ev) {
        const nextKey = this.getNextKey(this.overflows);
        const prevKey = this.getPrevKey(this.overflows);
        const peekAtIndex = makePeeker(this.buttonElements);
        const isUnmodified = areEqual(this.focusedIndex);
        this.focusedIndex = seekFocusedIndex(prevKey, nextKey, peekAtIndex)(this.focusedIndex, ev);
        this.focusButton(this.focusedIndex, ev);
        return !isUnmodified(this.focusedIndex);
    }
    getButtonGroup() {
        return this.overflows ? this.overflowButtonGroup : this.toolbarButtonGroup;
    }
    focusButton(index, ev) {
        // Guard against focusing twice on mousedown.
        if (!ev.type || ev.type === 'focus' || ev.type === 'keydown') {
            this.buttonElements[index].focus();
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ToolBarButtonGroupComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ToolBarButtonGroupComponent.prototype, "selection", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ToolBarButtonGroupComponent.prototype, "width", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ToolBarButtonGroupComponent.prototype, "look", void 0);
__decorate([
    ViewChild('toolbarTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], ToolBarButtonGroupComponent.prototype, "toolbarTemplate", void 0);
__decorate([
    ViewChild('popupTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], ToolBarButtonGroupComponent.prototype, "popupTemplate", void 0);
__decorate([
    ContentChildren(forwardRef(() => ToolBarButtonComponent)),
    __metadata("design:type", QueryList)
], ToolBarButtonGroupComponent.prototype, "buttonComponents", void 0);
__decorate([
    ViewChild('toolbarButtonGroup', { static: false }),
    __metadata("design:type", ButtonGroupComponent)
], ToolBarButtonGroupComponent.prototype, "toolbarButtonGroup", void 0);
__decorate([
    ViewChild('overflowButtonGroup', { static: false }),
    __metadata("design:type", ButtonGroupComponent)
], ToolBarButtonGroupComponent.prototype, "overflowButtonGroup", void 0);
ToolBarButtonGroupComponent = ToolBarButtonGroupComponent_1 = __decorate([
    Component({
        exportAs: 'kendoToolBarButtonGroup',
        // tslint:disable-next-line:no-forward-ref
        providers: [LocalizationService, { provide: ToolBarToolComponent, useExisting: forwardRef(() => ToolBarButtonGroupComponent_1) }],
        selector: 'kendo-toolbar-buttongroup',
        template: `
        <ng-template #toolbarTemplate>
            <kendo-buttongroup
                #toolbarButtonGroup
                [tabIndex]="-1"
                [selection]="selection"
                [disabled]="disabled"
                [look]="look"
                [width]="width"
                (navigate)="onNavigate($event)"
                (focus)="onFocus()"
            >
                <span
                    kendoButton
                    *ngFor="let button of buttonComponents"
                    [ngStyle]="button.style"
                    [ngClass]="button.className"
                    [attr.title]="button.title"
                    [disabled]="button.disabled"
                    [togglable]="button.togglable"
                    [primary]="button.primary"
                    [selected]="button.selected"
                    [icon]="button.toolbarOptions.icon"
                    [iconClass]="button.toolbarOptions.iconClass"
                    [imageUrl]="button.toolbarOptions.imageUrl"
                    (click)="button.click.emit($event); onButtonClick($event)"
                    (selectedChange)="selectedChangeHandler($event, button)"
                >
                    {{ button.toolbarOptions.text }}
                </span>
            </kendo-buttongroup>
        </ng-template>
        <ng-template #popupTemplate>
            <kendo-buttongroup
                #overflowButtonGroup
                class="k-overflow-button"
                [tabIndex]="-1"
                [selection]="selection"
                [disabled]="disabled"
                [look]="look"
                [width]="width"
            >
                <span
                    kendoButton
                    class="k-overflow-button"
                    *ngFor="let button of buttonComponents"
                    [ngStyle]="button.style"
                    [ngClass]="button.className"
                    [attr.title]="button.title"
                    [disabled]="button.disabled"
                    [togglable]="button.togglable"
                    [primary]="button.primary"
                    [selected]="button.selected"
                    [icon]="button.overflowOptions.icon"
                    [iconClass]="button.overflowOptions.iconClass"
                    [imageUrl]="button.overflowOptions.imageUrl"
                    (click)="button.click.emit($event); onButtonClick($event)"
                    (selectedChange)="selectedChangeHandler($event, button)"
                >
                    {{ button.overflowOptions.text }}
                </span>
            </kendo-buttongroup>
        </ng-template>
    `
    }),
    __metadata("design:paramtypes", [LocalizationService])
], ToolBarButtonGroupComponent);

/**
 * @hidden
 */
let ToolBarButtonListComponent = class ToolBarButtonListComponent {
    /**
     * @hidden
     */
    constructor() {
        this.disabled = false;
        this.look = 'default';
        this.itemClick = new EventEmitter();
    }
    getText(dataItem) {
        if (dataItem) {
            return this.textField ? dataItem[this.textField] : dataItem.text || dataItem;
        }
        return undefined;
    }
    onClick(item, index) {
        const dataItem = this.data[index];
        if (item.click) {
            item.click(dataItem);
        }
        this.itemClick.emit(dataItem);
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array)
], ToolBarButtonListComponent.prototype, "data", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ToolBarButtonListComponent.prototype, "textField", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ToolBarButtonListComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ToolBarButtonListComponent.prototype, "look", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ToolBarButtonListComponent.prototype, "itemClick", void 0);
__decorate([
    ViewChildren('button'),
    __metadata("design:type", QueryList)
], ToolBarButtonListComponent.prototype, "buttons", void 0);
ToolBarButtonListComponent = __decorate([
    Component({
        selector: 'kendo-toolbar-buttonlist',
        template: `
        <button
            #button
            type="button"
            tabindex="-1"
            kendoButton
            style="padding-left: 16px"
            class="k-overflow-button"
            *ngFor="let item of data; let i = index"
            [disabled]="disabled || item.disabled"
            [icon]="item.icon"
            [iconClass]="item.iconClass"
            [imageUrl]="item.imageUrl"
            [look]="look"
            (click)="onClick(item, i)"
        >
            {{ getText(item) }}
        </button>
    `
    })
], ToolBarButtonListComponent);

var ToolBarDropDownButtonComponent_1;
/**
 * Represents the [Kendo UI ToolBar DropDownButton for Angular]({% slug controltypes_toolbar %}#toc-dropdownbuttons).
 */
let ToolBarDropDownButtonComponent = ToolBarDropDownButtonComponent_1 = class ToolBarDropDownButtonComponent extends ToolBarToolComponent {
    constructor() {
        super();
        // showText and showIcon showIcon should be declared first
        /**
         * Defines the location of the button text that will be displayed.
         */
        this.showText = 'both';
        /**
         * Defines the location of the button icon that will be displayed.
         */
        this.showIcon = 'both';
        /**
         * Changes the visual appearance by using alternative styling options.
         * ([see example]({% slug controltypes_toolbar %}#toc-split-buttons)).
         *
         * The available values are:
         * * `flat`
         * * `outline`
         */
        this.look = 'default';
        /**
         * Adds visual weight to the default button and makes it primary.
         */
        this.primary = false;
        /**
         * Fires each time the user clicks a DropDownButton item.
         * The event data contains the data item that is bound to the clicked list item.
         */
        this.itemClick = new EventEmitter();
        /**
         * Fires each time the popup is about to open.
         * This event is preventable. If you cancel the event, the popup will remain closed.
         */
        this.open = new EventEmitter();
        /**
         * Fires each time the popup is about to close.
         * This event is preventable. If you cancel the event, the popup will remain open.
         */
        this.close = new EventEmitter();
        this.toolbarOptions = {
            text: '',
            icon: '',
            iconClass: '',
            imageUrl: ''
        };
        this.overflowOptions = {
            text: '',
            icon: '',
            iconClass: '',
            imageUrl: ''
        };
        this._popupSettings = { animate: true, popupClass: '' };
        this.focusedIndex = -1;
        this.getNextKey = getNextKey();
        this.getPrevKey = getPrevKey();
    }
    /**
     * Sets the text of the DropDownButton
     * ([see example]({% slug controltypes_toolbar %}#toc-dropdownbuttons).
     */
    set text(text) {
        this.toolbarOptions.text = getValueForLocation(text, this.showText, false);
        this.overflowOptions.text = getValueForLocation(text, this.showText, true);
    }
    /**
     * Defines an icon that will be rendered next to the button text.
     */
    set icon(icon) {
        this.toolbarOptions.icon = getValueForLocation(icon, this.showIcon, false);
        this.overflowOptions.icon = getValueForLocation(icon, this.showIcon, true);
    }
    /**
     * Defines an icon with a custom CSS class that will be rendered next to the button text.
     */
    set iconClass(iconClass) {
        this.toolbarOptions.iconClass = getValueForLocation(iconClass, this.showIcon, false);
        this.overflowOptions.iconClass = getValueForLocation(iconClass, this.showIcon, true);
    }
    /**
     * Defines the location of an image that will be displayed next to the button text.
     */
    set imageUrl(imageUrl) {
        this.toolbarOptions.imageUrl = getValueForLocation(imageUrl, this.showIcon, false);
        this.overflowOptions.imageUrl = getValueForLocation(imageUrl, this.showIcon, true);
    }
    /**
     * Configures the popup of the DropDownButton.
     *
     * The available options are:
     * - `animate:Boolean`&mdash;Controls the popup animation. By default, the open and close animations are enabled.
     * - `popupClass:String`&mdash;Specifies a list of CSS classes that are used to style the popup.
     */
    set popupSettings(settings) {
        this._popupSettings = Object.assign({ animate: true, popupClass: '' }, settings);
    }
    get popupSettings() {
        return this._popupSettings;
    }
    /**
     * Sets the data of the DropDownButton
     * ([see example]({% slug controltypes_toolbar %}#toc-dropdownbuttons)).
     *
     * > The data has to be provided in an array-like list.
     */
    set data(data) {
        this._data = data || [];
    }
    get data() {
        if (!this._data) {
            this.data = [];
        }
        return this._data;
    }
    get overflowButtons() {
        return [...this.overflowDropDownButtonButtonList.buttons.toArray().filter(b => !b.nativeElement.disabled)];
    }
    /**
     * @hidden
     */
    onButtonListClick(ev) {
        this.focusedIndex = this.overflowDropDownButtonButtonList.buttons
            .toArray()
            .findIndex(b => b.nativeElement.contains(ev.target));
    }
    /**
     * @hidden
     */
    canFocus() {
        return !this.disabled;
    }
    /**
     * @hidden
     */
    focus(ev = {}) {
        if (!this.overflows) {
            if (ev.type === 'focus' || ev.type === 'keydown') {
                this.toolbarDropDownButton.focus();
            }
        }
        else if (this.overflowButtons.length > 0) {
            this.focusedIndex = getIndexOfFocused(this.getPrevKey(), this.getNextKey(), this.overflowButtons.map(ob => ob.nativeElement))(ev);
            this.focusButton(this.focusedIndex, ev);
        }
    }
    /**
     * @hidden
     */
    handleKey(ev) {
        if (!this.overflows && (ev.keyCode === this.getPrevKey(this.overflows) || ev.keyCode === this.getNextKey(this.overflows))) {
            return false;
        }
        if (this.overflows) {
            const peekAtIndex = makePeeker(this.overflowButtons);
            const isUnmodified = areEqual(this.focusedIndex);
            this.focusedIndex = seekFocusedIndex(this.getPrevKey(), this.getNextKey(), peekAtIndex)(this.focusedIndex, ev);
            this.focusButton(this.focusedIndex, ev);
            return !isUnmodified(this.focusedIndex);
        }
    }
    focusButton(index, ev) {
        if (!ev.type || ev.type === 'focus' || ev.type === 'keydown') {
            this.overflowButtons[index].nativeElement.focus();
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], ToolBarDropDownButtonComponent.prototype, "showText", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ToolBarDropDownButtonComponent.prototype, "showIcon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], ToolBarDropDownButtonComponent.prototype, "text", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], ToolBarDropDownButtonComponent.prototype, "icon", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], ToolBarDropDownButtonComponent.prototype, "iconClass", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], ToolBarDropDownButtonComponent.prototype, "imageUrl", null);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], ToolBarDropDownButtonComponent.prototype, "popupSettings", null);
__decorate([
    Input(),
    __metadata("design:type", String)
], ToolBarDropDownButtonComponent.prototype, "buttonClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ToolBarDropDownButtonComponent.prototype, "textField", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ToolBarDropDownButtonComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ToolBarDropDownButtonComponent.prototype, "look", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], ToolBarDropDownButtonComponent.prototype, "data", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ToolBarDropDownButtonComponent.prototype, "primary", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ToolBarDropDownButtonComponent.prototype, "itemClick", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ToolBarDropDownButtonComponent.prototype, "open", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ToolBarDropDownButtonComponent.prototype, "close", void 0);
__decorate([
    ViewChild('toolbarTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], ToolBarDropDownButtonComponent.prototype, "toolbarTemplate", void 0);
__decorate([
    ViewChild('popupTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], ToolBarDropDownButtonComponent.prototype, "popupTemplate", void 0);
__decorate([
    ViewChild('dropdownButton', { read: ElementRef, static: true }),
    __metadata("design:type", ElementRef)
], ToolBarDropDownButtonComponent.prototype, "dropdownButton", void 0);
__decorate([
    ViewChild(DropDownButtonComponent, { static: false }),
    __metadata("design:type", DropDownButtonComponent)
], ToolBarDropDownButtonComponent.prototype, "dropDownButtonComponent", void 0);
__decorate([
    ViewChild('toolbarDropDownButton', { static: false }),
    __metadata("design:type", DropDownButtonComponent)
], ToolBarDropDownButtonComponent.prototype, "toolbarDropDownButton", void 0);
__decorate([
    ViewChild('overflowDropDownButtonButtonList', { static: false }),
    __metadata("design:type", ToolBarButtonListComponent)
], ToolBarDropDownButtonComponent.prototype, "overflowDropDownButtonButtonList", void 0);
ToolBarDropDownButtonComponent = ToolBarDropDownButtonComponent_1 = __decorate([
    Component({
        exportAs: 'kendoToolBarDropDownButton',
        // tslint:disable-next-line:no-forward-ref
        providers: [{ provide: ToolBarToolComponent, useExisting: forwardRef(() => ToolBarDropDownButtonComponent_1) }],
        selector: 'kendo-toolbar-dropdownbutton',
        template: `
        <ng-template #toolbarTemplate>
            <kendo-dropdownbutton
                #toolbarDropDownButton
                [icon]="toolbarOptions.icon"
                [iconClass]="toolbarOptions.iconClass"
                [imageUrl]="toolbarOptions.imageUrl"
                [buttonClass]="buttonClass"
                [disabled]="disabled"
                [tabIndex]="-1"
                [data]="data"
                [textField]="textField"
                [popupSettings]="popupSettings"
                [look]="look"
                [primary]="primary"
                (open)="open.emit($event)"
                (close)="close.emit($event)"
                (itemClick)="itemClick.emit($event)"
            >
                {{ toolbarOptions.text }}
            </kendo-dropdownbutton>
        </ng-template>
        <ng-template #popupTemplate>
            <button
                type="button"
                tabindex="-1"
                kendoButton
                class="k-overflow-button"
                [disabled]="true"
                [icon]="overflowOptions.icon"
                [iconClass]="overflowOptions.iconClass"
                [imageUrl]="overflowOptions.imageUrl"
                [ngClass]="buttonClass"
                (click)="itemClick.emit($event)"
            >
                {{ overflowOptions.text }}
            </button>
            <kendo-toolbar-buttonlist
                #overflowDropDownButtonButtonList
                [data]="data"
                [disabled]="disabled"
                [textField]="textField"
                (itemClick)="itemClick.emit($event)"
                (click)="onButtonListClick($event)"
            >
            </kendo-toolbar-buttonlist>
        </ng-template>
    `
    }),
    __metadata("design:paramtypes", [])
], ToolBarDropDownButtonComponent);

var ToolBarSplitButtonComponent_1;
/**
 * Represents the [Kendo UI ToolBar SplitButton for Angular]({% slug controltypes_toolbar %}#toc-splitbuttons).
 */
let ToolBarSplitButtonComponent = ToolBarSplitButtonComponent_1 = class ToolBarSplitButtonComponent extends ToolBarToolComponent {
    constructor() {
        super();
        // showText and showIcon showIcon should be declared first
        /**
         * Specifies where button text should be displayed
         */
        this.showText = 'both';
        /**
         * Specifies where button icon should be displayed
         */
        this.showIcon = 'both';
        /**
         * Changes the visual appearance by using alternative styling options
         * ([see example]({% slug controltypes_toolbar %}#toc-split-buttons)).
         *
         * The available values are:
         * * `default`
         * * `flat`
         * * `outline`
         */
        this.look = 'default';
        /**
         * Specifies the name of the [font icon]({% slug icons %}#toc-list-of-font-icons) that will
         * be rendered for the button which opens the popup.
         */
        this.arrowButtonIcon = 'arrow-s';
        /**
         * Configures the text field of the button-list popup.
         */
        this.textField = 'text';
        /**
         * Fires each time the user clicks the main button.
         */
        this.buttonClick = new EventEmitter();
        /**
         * Fires each time the user clicks the drop-down list.
         * The event data contains the data item that is bound to the clicked list item.
         */
        this.itemClick = new EventEmitter();
        /**
         * Fires each time the popup is about to open.
         * This event is preventable. If you cancel the event, the popup will remain closed.
         */
        this.open = new EventEmitter();
        /**
         * Fires each time the popup is about to close.
         * This event is preventable. If you cancel the event, the popup will remain open.
         */
        this.close = new EventEmitter();
        this.toolbarOptions = {
            text: '',
            icon: '',
            iconClass: '',
            imageUrl: ''
        };
        this.overflowOptions = {
            text: '',
            icon: '',
            iconClass: '',
            imageUrl: ''
        };
        this._popupSettings = { animate: true, popupClass: '' };
        this.focusedIndex = -1;
        this.getNextKey = getNextKey();
        this.getPrevKey = getPrevKey();
    }
    /**
     * Sets the text of the SplitButton ([see example]({% slug controltypes_toolbar %}#toc-splitbuttons).
     */
    set text(text) {
        this.toolbarOptions.text = getValueForLocation(text, this.showText, false);
        this.overflowOptions.text = getValueForLocation(text, this.showText, true);
    }
    /**
     * Defines the icon that will be rendered next to the button text
     * ([see example]({% slug controltypes_toolbar %}#toc-splitbuttons)).
     */
    set icon(icon) {
        this.toolbarOptions.icon = getValueForLocation(icon, this.showIcon, false);
        this.overflowOptions.icon = getValueForLocation(icon, this.showIcon, true);
    }
    /**
     * Defines an icon with a custom CSS class that will be rendered next to the button text.
     */
    set iconClass(iconClass) {
        this.toolbarOptions.iconClass = getValueForLocation(iconClass, this.showIcon, false);
        this.overflowOptions.iconClass = getValueForLocation(iconClass, this.showIcon, true);
    }
    /**
     * Defines the location of an image that will be displayed next to the button text.
     */
    set imageUrl(imageUrl) {
        this.toolbarOptions.imageUrl = getValueForLocation(imageUrl, this.showIcon, false);
        this.overflowOptions.imageUrl = getValueForLocation(imageUrl, this.showIcon, true);
    }
    /**
     * Configures the popup of the SplitButton.
     *
     * The available options are:
     * - `animate:Boolean`&mdash;Controls the popup animation. By default, the open and close animations are enabled.
     * - `popupClass:String`&mdash;Specifies a list of CSS classes that are used to style the popup.
     */
    set popupSettings(value) {
        this._popupSettings = value;
    }
    get popupSettings() {
        if (!this._popupSettings) {
            this._popupSettings = { animate: true, popupClass: '' };
        }
        return this._popupSettings;
    }
    /**
     * Sets the data of the SplitButton ([see example]({% slug controltypes_toolbar %}#toc-splitbuttons)).
     *
     * > The data has to be provided in an array-like list.
     */
    set data(data) {
        this._data = data || [];
    }
    get data() {
        if (!this._data) {
            this.data = [];
        }
        return this._data;
    }
    get overflowButtons() {
        return [this.overflowSplitButton, ...this.overflowSplitButtonButtonList.buttons.toArray().filter(b => !b.nativeElement.disabled)];
    }
    /**
     * @hidden
     */
    onButtonListClick(ev) {
        this.focusedIndex = this.overflowButtons.findIndex(b => b.nativeElement.contains(ev.target));
    }
    /**
     * @hidden
     */
    onMainButtonClick(ev) {
        this.buttonClick.emit(ev);
        this.focusedIndex = 0;
    }
    /**
     * @hidden
     */
    canFocus() {
        return !this.disabled;
    }
    /**
     * @hidden
     */
    focus(ev = {}) {
        if (!this.overflows) {
            if (ev.type === 'focus' || ev.type === 'keydown') {
                this.toolbarSplitButton.focus();
            }
        }
        else if (this.overflowButtons.length > 0) {
            this.focusedIndex = getIndexOfFocused(this.getPrevKey(), this.getNextKey(), this.overflowButtons.map(ob => ob.nativeElement))(ev);
            this.focusButton(this.focusedIndex, ev);
        }
    }
    /**
     * @hidden
     */
    handleKey(ev) {
        if (!this.overflows && (ev.keyCode === this.getPrevKey(this.overflows) || ev.keyCode === this.getNextKey(this.overflows))) {
            return false;
        }
        if (this.overflows) {
            const peekAtIndex = makePeeker(this.overflowButtons);
            const isUnmodified = areEqual(this.focusedIndex);
            this.focusedIndex = seekFocusedIndex(this.getPrevKey(), this.getNextKey(), peekAtIndex)(this.focusedIndex, ev);
            this.focusButton(this.focusedIndex, ev);
            return !isUnmodified(this.focusedIndex);
        }
    }
    focusButton(index, ev) {
        if (!ev.type || ev.type === 'focus' || ev.type === 'keydown' || ev.type === 'click') {
            this.overflowButtons[index].nativeElement.focus();
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], ToolBarSplitButtonComponent.prototype, "showText", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ToolBarSplitButtonComponent.prototype, "showIcon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], ToolBarSplitButtonComponent.prototype, "text", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], ToolBarSplitButtonComponent.prototype, "icon", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], ToolBarSplitButtonComponent.prototype, "iconClass", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], ToolBarSplitButtonComponent.prototype, "imageUrl", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ToolBarSplitButtonComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ToolBarSplitButtonComponent.prototype, "look", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], ToolBarSplitButtonComponent.prototype, "popupSettings", null);
__decorate([
    Input(),
    __metadata("design:type", String)
], ToolBarSplitButtonComponent.prototype, "buttonClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ToolBarSplitButtonComponent.prototype, "arrowButtonClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ToolBarSplitButtonComponent.prototype, "arrowButtonIcon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ToolBarSplitButtonComponent.prototype, "textField", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], ToolBarSplitButtonComponent.prototype, "data", null);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ToolBarSplitButtonComponent.prototype, "buttonClick", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ToolBarSplitButtonComponent.prototype, "itemClick", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ToolBarSplitButtonComponent.prototype, "open", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ToolBarSplitButtonComponent.prototype, "close", void 0);
__decorate([
    ViewChild('toolbarTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], ToolBarSplitButtonComponent.prototype, "toolbarTemplate", void 0);
__decorate([
    ViewChild('popupTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], ToolBarSplitButtonComponent.prototype, "popupTemplate", void 0);
__decorate([
    ViewChild('toolbarSplitButton', { static: false }),
    __metadata("design:type", SplitButtonComponent)
], ToolBarSplitButtonComponent.prototype, "toolbarSplitButton", void 0);
__decorate([
    ViewChild('overflowSplitButton', { read: ElementRef, static: false }),
    __metadata("design:type", ElementRef)
], ToolBarSplitButtonComponent.prototype, "overflowSplitButton", void 0);
__decorate([
    ViewChild('overflowSplitButtonButtonList', { static: false }),
    __metadata("design:type", ToolBarButtonListComponent)
], ToolBarSplitButtonComponent.prototype, "overflowSplitButtonButtonList", void 0);
ToolBarSplitButtonComponent = ToolBarSplitButtonComponent_1 = __decorate([
    Component({
        exportAs: 'kendoToolBarSplitButton',
        // tslint:disable-next-line:no-forward-ref
        providers: [{ provide: ToolBarToolComponent, useExisting: forwardRef(() => ToolBarSplitButtonComponent_1) }],
        selector: 'kendo-toolbar-splitbutton',
        template: `
        <ng-template #toolbarTemplate>
            <kendo-splitbutton
                #toolbarSplitButton
                [data]="data"
                [text]="toolbarOptions.text"
                [icon]="toolbarOptions.icon"
                [iconClass]="toolbarOptions.iconClass"
                [imageUrl]="toolbarOptions.imageUrl"
                [buttonClass]="buttonClass"
                [arrowButtonClass]="arrowButtonClass"
                [arrowButtonIcon]="arrowButtonIcon"
                [disabled]="disabled"
                [tabIndex]="-1"
                [textField]="textField"
                [popupSettings]="popupSettings"
                [look]="look"
                (buttonClick)="buttonClick.emit($event)"
                (open)="open.emit($event)"
                (close)="close.emit($event)"
                (itemClick)="itemClick.emit($event)"
            >
            </kendo-splitbutton>
        </ng-template>
        <ng-template #popupTemplate>
            <button
                #overflowSplitButton
                type="button"
                tabindex="-1"
                kendoButton
                class="k-overflow-button"
                [disabled]="disabled"
                [icon]="overflowOptions.icon"
                [iconClass]="overflowOptions.iconClass"
                [imageUrl]="overflowOptions.imageUrl"
                [look]="look"
                [ngClass]="buttonClass"
                (click)="buttonClick.emit($event)"
                (click)="onMainButtonClick($event)"
            >
                {{ overflowOptions.text }}
            </button>
            <kendo-toolbar-buttonlist
                #overflowSplitButtonButtonList
                [look]="look"
                [data]="data"
                [disabled]="disabled"
                [textField]="textField"
                (itemClick)="itemClick.emit($event)"
                (click)="onButtonListClick($event)"
            >
            </kendo-toolbar-buttonlist>
        </ng-template>
    `
    }),
    __metadata("design:paramtypes", [])
], ToolBarSplitButtonComponent);

var ToolBarSeparatorComponent_1;
/**
 * Represents the [Kendo UI ToolBar Separator for Angular]({% slug controltypes_toolbar %}#toc-separators).
 */
let ToolBarSeparatorComponent = ToolBarSeparatorComponent_1 = class ToolBarSeparatorComponent extends ToolBarToolComponent {
    constructor() {
        super();
    }
    /**
     * @hidden
     */
    canFocus() {
        return false;
    }
    // If this is not here, the docs display info from its parent(ToolBarToolComponent).
    /**
     * @hidden
     */
    focus() {
        /* noop */
    }
    // If this is not here, the docs display info from its parent(ToolBarToolComponent).
    /**
     * @hidden
     */
    handleKey() {
        return false;
    }
    ngAfterViewInit() {
        if (!this.popupTemplate) {
            this.popupTemplate = this.toolbarTemplate;
        }
    }
};
__decorate([
    ViewChild('toolbarTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], ToolBarSeparatorComponent.prototype, "toolbarTemplate", void 0);
__decorate([
    ViewChild('popupTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], ToolBarSeparatorComponent.prototype, "popupTemplate", void 0);
__decorate([
    ViewChild('separator', { static: false }),
    __metadata("design:type", ElementRef)
], ToolBarSeparatorComponent.prototype, "separator", void 0);
ToolBarSeparatorComponent = ToolBarSeparatorComponent_1 = __decorate([
    Component({
        exportAs: 'kendoToolBarSeparator',
        // tslint:disable-next-line:no-forward-ref
        providers: [{ provide: ToolBarToolComponent, useExisting: forwardRef(() => ToolBarSeparatorComponent_1) }],
        selector: 'kendo-toolbar-separator',
        template: `
        <ng-template #toolbarTemplate>
            <div class="k-separator"></div>
        </ng-template>
    `
    }),
    __metadata("design:paramtypes", [])
], ToolBarSeparatorComponent);

/**
 * @hidden
 */
let RendererService = class RendererService {
    getElement() {
        return this.element.nativeElement;
    }
    querySelector(selector) {
        return this.element.nativeElement.querySelector(selector);
    }
    querySelectorAll(selector) {
        return this.element.nativeElement.querySelectorAll(selector);
    }
    findFocusable() {
        return findFocusable(this.element.nativeElement, false);
    }
    findFocusableChild(element) {
        if (!element) {
            element = this.findFocusable();
        }
        return findFocusableChild(element, false);
    }
    findNextFocusableSibling(element) {
        if (!element) {
            element = this.findFocusable();
        }
        return findFocusableSibling(element, false);
    }
    findPrevFocusableSibling(element) {
        if (!element) {
            element = this.findFocusable();
        }
        return findFocusableSibling(element, false, true);
    }
    setAttribute(element, attr, value) {
        this.renderer.setAttribute(element, attr, value);
    }
};
RendererService = __decorate([
    Injectable()
], RendererService);

/**
 * @hidden
 */
let ToolBarRendererComponent = class ToolBarRendererComponent {
    constructor(element, renderer, rendererService, refreshService) {
        this.element = element;
        this.renderer = renderer;
        this.rendererService = rendererService;
        this.refreshService = refreshService;
        this.rendererClick = new EventEmitter();
        this.rendererService.element = element;
        this.rendererService.renderer = this;
        this.refreshSubscription = this.refreshService.onRefresh.subscribe((tool) => {
            if (this.tool === tool) {
                this.refresh();
            }
        });
    }
    onClick(ev) {
        this.rendererClick.emit({ context: this, event: ev });
    }
    ngOnInit() {
        if (this.resizable) {
            if (this.location === 'toolbar') {
                this.template = this.tool.toolbarTemplate;
                this.renderer.setStyle(this.element.nativeElement, 'visibility', 'hidden');
                this.renderer.setStyle(this.element.nativeElement, 'display', 'none');
            }
            else {
                this.template = this.tool.popupTemplate;
                this.renderer.setStyle(this.element.nativeElement, 'display', 'none');
            }
        }
        else {
            this.tool.overflows = false;
            this.template = this.tool.toolbarTemplate;
            this.renderer.setStyle(this.element.nativeElement, 'visibility', 'display');
            this.renderer.setStyle(this.element.nativeElement, 'display', 'inline-block');
        }
    }
    ngOnDestroy() {
        this.refreshSubscription.unsubscribe();
    }
    ngAfterViewInit() {
        if (this.resizable) {
            this.refresh();
        }
    }
    /**
     * @hidden
     */
    get width() {
        return this.tool.overflows ? 0 : outerWidth(this.element.nativeElement);
    }
    /**
     * @hidden
     */
    isDisplayed() {
        return this.element.nativeElement.style.display !== 'none';
    }
    /**
     * @hidden
     */
    refresh() {
        if (this.resizable) {
            if (this.location === 'toolbar') {
                this.renderer.setStyle(this.element.nativeElement, 'visibility', this.tool.visibility);
                this.renderer.setStyle(this.element.nativeElement, 'display', this.tool.toolbarDisplay);
            }
            else {
                this.renderer.setStyle(this.element.nativeElement, 'display', this.tool.overflowDisplay);
            }
        }
    }
    /**
     * @hidden
     */
    setAttribute(element, attr, value) {
        this.renderer.setAttribute(element, attr, value);
    }
};
__decorate([
    Input(),
    __metadata("design:type", ToolBarToolComponent)
], ToolBarRendererComponent.prototype, "tool", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ToolBarRendererComponent.prototype, "location", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ToolBarRendererComponent.prototype, "resizable", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ToolBarRendererComponent.prototype, "rendererClick", void 0);
__decorate([
    HostListener('click', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ToolBarRendererComponent.prototype, "onClick", null);
ToolBarRendererComponent = __decorate([
    Component({
        exportAs: 'kendoToolBarRenderer',
        providers: [RendererService],
        selector: 'kendo-toolbar-renderer',
        template: `
        <ng-container *ngIf="location === 'toolbar'">
            <ng-template [ngTemplateOutlet]="template"></ng-template>
        </ng-container>
        <ng-container *ngIf="location === 'overflow' && tool.responsive">
            <ng-template [ngTemplateOutlet]="template"></ng-template>
        </ng-container>
    `
    }),
    __metadata("design:paramtypes", [ElementRef,
        Renderer2,
        RendererService,
        RefreshService])
], ToolBarRendererComponent);

/**
 * @hidden
 */
class ToolbarMessages extends ComponentMessages {
}
__decorate([
    Input(),
    __metadata("design:type", String)
], ToolbarMessages.prototype, "moreToolsTitle", void 0);

var LocalizedToolbarMessagesDirective_1;
/**
 * @hidden
 */
let LocalizedToolbarMessagesDirective = LocalizedToolbarMessagesDirective_1 = class LocalizedToolbarMessagesDirective extends ToolbarMessages {
    constructor(service) {
        super();
        this.service = service;
    }
};
LocalizedToolbarMessagesDirective = LocalizedToolbarMessagesDirective_1 = __decorate([
    Directive({
        providers: [
            {
                provide: ToolbarMessages,
                useExisting: forwardRef(() => LocalizedToolbarMessagesDirective_1) // tslint:disable-line:no-forward-ref
            }
        ],
        selector: '[kendoToolbarLocalizedMessages]'
    }),
    __metadata("design:paramtypes", [LocalizationService])
], LocalizedToolbarMessagesDirective);

var ToolbarCustomMessagesComponent_1;
/**
 * Custom component messages override default component messages.
 */
let ToolbarCustomMessagesComponent = ToolbarCustomMessagesComponent_1 = class ToolbarCustomMessagesComponent extends ToolbarMessages {
    constructor(service) {
        super();
        this.service = service;
    }
    get override() {
        return true;
    }
};
ToolbarCustomMessagesComponent = ToolbarCustomMessagesComponent_1 = __decorate([
    Component({
        providers: [
            {
                provide: ToolbarMessages,
                useExisting: forwardRef(() => ToolbarCustomMessagesComponent_1) // tslint:disable-line:no-forward-ref
            }
        ],
        selector: 'kendo-toolbar-messages',
        template: ``
    }),
    __metadata("design:paramtypes", [LocalizationService])
], ToolbarCustomMessagesComponent);

const TOOLBAR_TOOLS = [
    ToolBarToolComponent,
    ToolBarButtonComponent,
    ToolBarButtonGroupComponent,
    ToolBarDropDownButtonComponent,
    ToolBarSplitButtonComponent,
    ToolBarSeparatorComponent
];
const TOOLBAR_COMMON = [
    ToolBarRendererComponent,
    ToolBarButtonListComponent,
    ToolbarCustomMessagesComponent,
    LocalizedToolbarMessagesDirective
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }}) definition for the ToolBar component.
 *
 * The package exports:
 * - `ToolBarComponent`&mdash;The ToolBarComponent class.
 * - `ToolBarToolComponent`&mdash;The base Tool component class.
 * - `ToolBarButtonComponent`&mdash;The Button Tool component class.
 * - `ToolBarButtonGroupComponent`&mdash;The ButtonGroup Tool component class.
 * - `ToolBarDropDownButtonComponent`&mdash;The DropDownButton Tool component class.
 * - `ToolBarSplitButtonComponent`&mdash;The SplitButton Tool component class.
 * - `ToolBarSeparatorComponent`&mdash;The Separator Tool component class.
 */
let ToolBarModule = class ToolBarModule {
};
ToolBarModule = __decorate([
    NgModule({
        declarations: [ToolBarComponent, TOOLBAR_TOOLS, TOOLBAR_COMMON],
        exports: [ToolBarComponent, TOOLBAR_TOOLS, ToolbarCustomMessagesComponent, LocalizedToolbarMessagesDirective],
        imports: [CommonModule, ButtonsModule, PopupModule, ResizeSensorModule]
    })
], ToolBarModule);

/**
 * Generated bundle index. Do not edit.
 */

export { ToolbarMessages, NavigationService, RefreshService, ToolBarRendererComponent, RendererService, ToolBarButtonListComponent, ToolBarComponent, ToolBarToolComponent, ToolBarButtonComponent, ToolBarButtonGroupComponent, ToolBarDropDownButtonComponent, ToolBarSplitButtonComponent, ToolBarSeparatorComponent, ToolBarModule, LocalizedToolbarMessagesDirective, ToolbarCustomMessagesComponent };
